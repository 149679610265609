<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Воспитанники // <span class="group-name">Группа </span> - {{ groupInfo.groupName }}
          </template>
          <template v-slot:toolbar>
            <b-button @click="createStudent">{{ $t("common.new") }}</b-button>
<!--            <b-button @click="exportPasswords">Экспорт</b-button>-->
          </template>
          <template v-slot:body>

            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th>#</th>
                <th class="text-left">
                  {{ $t("studentsList.inn") }}
                </th>
                <th class="text-left">
                  {{ $t("studentsList.firstName") }}
                </th>
                <th class="text-left">
                  {{ $t("studentsList.name") }}
                </th>
                <th class="text-left">
                  {{ $t("studentsList.patronymic") }}
                </th>
                <th class="text-left">
                  {{ $t("studentsList.contactPhone") }}
                </th>   
                <th class="text-left">
                  {{ $t("studentsList.sex") }}
                </th>
                <th class="text-left">
                  {{ $t("studentsList.userName") }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, k) in students" :key="item.id" :class="isDropped(item)">
                <td>{{ k + 1 }}</td>
                <td>{{ item.inn }}</td>
                <td>{{ item.firstName }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.patronymic }}</td>
                <td>{{ item.contactPhone }}</td>
                <td>{{ showSex(item.sex) }}</td>
                <td>{{ item.userName }}</td>
                <td>
                  <router-link :to="{ name: 'PreschoolStudentInfo', params: { id: item.id }}" class="btn btn-icon btn-success">
                    <i class="fa fa-pen"></i>
                  </router-link>
                </td>
              </tr>
              </tbody>
            </table>
            <div>
              <b-modal ref="preschool-student-modal">
                <template v-slot:modal-title>
                  {{ $t("common.new") }}
                </template>
                <b-form>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.inn") }}
                    </template>
                    <b-form-input
                        v-model="form.inn"
                        :state="studentModelStates.inn"
                        @change="changeInn"
                    ></b-form-input>
                    <div class="invalid-feedback">{{ studentModelSaveError.inn }}</div>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.firstName") }}
                    </template>
                    <b-form-input
                        v-model="form.firstName"
                        :state="studentModelStates.firstName"
                    ></b-form-input>
                    <div class="invalid-feedback">{{ studentModelSaveError.firstName }}</div>
                  </b-form-group>

                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.name") }}
                    </template>
                    <b-form-input
                        v-model="form.name"
                        :state="studentModelStates.name"
                    ></b-form-input>
                    <div class="invalid-feedback">{{ studentModelSaveError.name }}</div>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.patronymic") }}
                    </template>
                    <b-form-input
                        v-model="form.patronymic"
                        required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.birthDate") }}
                    </template>
                    <b-form-datepicker
                        v-model="form.birthDate"
                        :state="studentModelStates.birthDate"
                    ></b-form-datepicker>
                    <div class="invalid-feedback">{{ studentModelSaveError.birthDate }}</div>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.sex") }}
                    </template>
                    <b-form-select                        
                        v-model="form.sex"
                        :state="studentModelStates.sex"
                        :options="sexOptions"
                        required
                    ></b-form-select>
                    <div class="invalid-feedback">{{ studentModelSaveError.sex }}</div>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.contactPhone") }}
                    </template>
                    <b-form-input
                        v-model="form.contactPhone"
                    ></b-form-input>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onSubmit" variant="primary" id="save-preschool-student-data-button">{{
                      $t("common.save")
                    }}
                  </b-button>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                      $t("common.cancel")
                    }}
                  </b-button>
                </template>
              </b-modal>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "PreschoolStudent",
  components: {
    KTPortlet
  },
  data() {
    return {
      groupId: null,
      students: null,
      dialog: true,
      socialPassportCategories: [],
      dropReasons: [],
      form: {
        inn: "",
        firstName: "",
        name: "",
        patronymic: "",
        parentName: "",
        contactPhone: "",
        birthDate: ""
      },
      dropForm: {
        dropDate: "",
        dropReasonId: "",
        studentToGroupId: "",
      },
      studentModelStates: {},
      studentModelSaveError: {},
      studentDropModelStates: {},
      studentDropModelSaveError: {},
      show: true,
      groupInfo: {},
      dropStudentName: "",
      sexOptions:DictionariesService.sexOptions(),
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      let submitButton = document.getElementById('save-preschool-student-data-button');
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");

      evt.preventDefault();
      let $this = this;
      this.form.groupId = this.$route.params.id;
      this.studentModelStates = {};
      this.studentModelSaveError = {};

      ApiService.postSecured("preschoolStudent/save", this.form)
          .then(function () {
            $this.$refs["preschool-student-modal"].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.studentModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.studentModelStates[i] = false;
              }
            }
          })
          .finally(function () {
            submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
          });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["preschool-student-modal"].hide();
    },
    reloadData() {
      ApiService.querySecured("preschoolStudent/list", {
        params: {groupId: this.$route.params.id}
      }).then(({data}) => {
        this.students = data.list;
      }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("preschoolGroups/find", {
        params: {id: this.$route.params.id}
      }).then(({data}) => {
        this.groupInfo = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    cleanModel() {
      this.form = {
        inn: "",
        firstName: "",
        name: "",
        patronymic: "",
        parentName: "",
        contactPhone: "",
        birthDate: null,
        sex: null
      };
      this.dropForm = {};
      this.dropStudentName = "";
    },
    createStudent(){
      this.form = {
        inn: "",
        firstName: "",
        name: "",
        patronymic: "",
        parentName: "",
        contactPhone: "",
        birthDate: null,
        sex: null,
      };

      this.$refs["preschool-student-modal"].show();
    },
    isDropped(student) {
      return student.isDropped === true ? "table-danger" : "";
    },
    showSex(sexId){
      for (let i in this.sexOptions){
        let sex = this.sexOptions[i];

        if(sex.value == sexId){
          return sex.text;
        }
      }

      return '';
    },
    changeInn(){
      let inn = this.form.inn.replace(/[^\d]/g, '');
      
      if (inn.length >= 9) {
        let sex = inn.substring(0, 1);

        console.log(sex);
        if (sex === '1') {
          this.form.sex = 1;
        }

        if (sex === '2') {
          this.form.sex = 2;
        }
        
        this.form.birthDate = new Date(parseInt(inn.substring(5, 9)), parseInt(inn.substring(3, 5)) - 1,  parseInt(inn.substring(1, 3)));
      }
    },
    exportPasswords(){
        ApiService.downloadSecured("students/exportToExcel?groupId=" + this.$route.params.id)
          .then(({data}) => {
            const url = URL.createObjectURL(new Blob([data], {
              type: 'application/vnd.ms-excel'
            }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'Ученики пароли.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    }
    /*,
    openStudentDashboard(student) {
      this.$router.push({
        name: "studentDashboard",
        params: { id: student.id }
      });
    }*/
  }
};
</script>
